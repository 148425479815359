.container {
  border-bottom: 1px solid #BCBCBC;
  padding: 0 2em;

  .header {
    width: 90%;
    margin: 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-right: 10px;
    padding-left: 10px;
  }

}
