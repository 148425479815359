.Menu {
  display: flex;
  width: 50%;

  .item {
    max-width: 25%;
    padding-right: 49px;

    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000;

    user-select: none;
    cursor: pointer;

    .active {
      color: #1890ff;
    }
  }
}
