.Container {
  width: 100%;
  max-width: 200px;

  .item {
    width: 100%;
    height: 36px;
    cursor: pointer;
    padding: 12px 36px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #F2F2F2;
    }

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
